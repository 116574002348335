import * as type from 'constants/action_types/subscriptions'

export const initialState = {
  upgradePlans: [],
  getUpgradePlansInfo: {
    isLoading: false
  }
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case type.PENDING_SUBSCRIPTION_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: true
        }
      }
    case type.COMPLETED_SUBSCRIPTION_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false
        }
      }
    case type.SET_UPGRADE_PLAN_INFORMATION:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export default reducer
