import {
  SET_SUPPLIERS_STATE,
  SET_SUPPLIERS_SEARCH_STATE,
  SET_SUPPLIERS_TRACKER_STATE
} from 'constants/action_types/suppliers'

export const initialState = {
  search: {
    searchValue: '',
    searchType: 'product',
    isLoading: false,
    isError: false,
    page: 1,
    data: undefined,
    filters: undefined,
    product: undefined // When searching by ASIN, the product data of the ASIN.
  },
  tracker: {
    page: 1
  }
}

function suppliers(state = initialState, action) {
  switch (action.type) {
    case SET_SUPPLIERS_STATE:
      return {
        ...state,
        ...action.payload
      }

    case SET_SUPPLIERS_SEARCH_STATE:
      return {
        ...state,
        search: {
          ...state.search,
          ...action.payload
        }
      }
    case SET_SUPPLIERS_TRACKER_STATE:
      return {
        ...state,
        tracker: {
          ...state.tracker,
          ...action.payload
        }
      }

    default:
      return state
  }
}

export default suppliers
