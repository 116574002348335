import * as types from '../constants/action_types/seats'

export const initialState = {
  paid: 0,
  included: null,
  total: null,
  used: null,
  available: null,

  subscription_item_id: null,

  stripe_plan: {},

  fetchSeats: {
    isLoading: false
  },

  seatQuantityForm: {
    isLoading: false
  }
}

function seats(state = initialState, action) {
  switch (action.type) {
    case types.PENDING_SEAT_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: true
        }
      }
    case types.RESET_SEAT_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false
        }
      }
    case types.SET_SEAT_DATA:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export default seats
