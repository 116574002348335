import * as type from 'constants/action_types'
import { getShowState } from 'helpers/core'

export const initialState = {
  loggedIn: true,

  login: {
    isLoading: false,
    completed: false
  },

  authy: {
    isLoading: false,
    completed: false
  },

  sendEmail: {
    isLoading: false,
    completed: false
  },

  email: '',
  password: '',
  authyCode: '',

  sessionValidations: {
    newPassword: '',
    newPasswordConfirmation: ''
  },

  showAuthyModal: false,
  showLoginModal: false,
  locationHistory: '/'
}

function sessions(state = initialState, action) {
  switch (action.type) {
    case type.PENDING_SESSION_ACTION:
      return {
        ...state,
        loggedIn: false,
        [action.payload]: {
          ...state[action.payload],
          isLoading: true
        }
      }
    case type.COMPLETED_SESSION_ACTION:
      return {
        ...state,
        loggedIn: true,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false,
          completed: true
        }
      }
    case type.RESET_SESSION_ACTION:
      return {
        ...state,
        loggedIn: false,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false,
          completed: false
        }
      }
    case type.SESSION_INPUT_FIELD_UPDATE:
      return {
        ...state,
        [action.payload.prop]: action.payload.value
      }
    case type.ADD_SESSION_VALIDATION_ERROR:
      const { field, message } = action.payload
      return {
        ...state,
        sessionValidations: {
          ...state.sessionValidations,
          [field]: message
        }
      }
    case type.TOGGLE_AUTHY_MODAL:
      return {
        ...state,
        showAuthyModal: getShowState(state.showAuthyModal),
        showLoginModal: false
      }
    case type.TOGGLE_LOGIN_MODAL:
      return {
        ...state,
        showLoginModal: getShowState(state.showLoginModal)
      }
    case type.COMPLETED_ADD_LOCATION_HISTORY:
      return {
        ...state,
        locationHistory: action.payload
      }
    default:
      return state
  }
}

export default sessions
