import * as types from '../constants/action_types/team'

export const initialState = {
  team: null,
  teams: [],
  teamsList: {
    isLoading: false
  },
  teamForm: {
    isLoading: false
  },
  teamDetails: {
    isLoading: false
  },
  teamDelete: {
    isLoading: false
  },
  addTeamMember: {
    isLoading: false
  },
  teamMemberDelete: {
    isLoading: false
  }
}

function team(state = initialState, action) {
  switch (action.type) {
    case types.RECEIVE_TEAMS:
      return { ...state, teams: action.payload }
    case types.RECEIVE_NEW_TEAM:
      return { ...state, teams: [...state.teams, action.payload] }
    case types.RECEIVE_TEAM: {
      let teams = [...state.teams]
      const index = teams.findIndex(t => t.id === action.payload.id)

      if (index !== -1) {
        teams[index] = action.payload
      } else {
        teams = [...state.teams, action.payload]
      }

      return {
        ...state,
        team: action.payload,
        teams
      }
    }
    case types.RECEIVE_DELETE_TEAM:
      return {
        ...state,
        teams: state.teams.filter(t => t.id !== action.payload.id)
      }
    case types.PENDING_TEAM_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: true
        }
      }
    case types.RESET_TEAM_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false
        }
      }
    default:
      return state
  }
}

export default team
