import * as types from '../../constants/action_types'

export const initialState = {
  fetch: {
    isLoading: false,
    completed: false
  },

  save: {
    isLoading: false,
    completed: false
  },

  data: {}
}

function alertSettings(state = initialState, action) {
  switch (action.type) {
    case types.PENDING_ALERT_SETTINGS_ACTION: {
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: true
        }
      }
    }
    case types.COMPLETED_ALERT_SETTINGS_ACTION: {
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false,
          completed: true
        }
      }
    }
    case types.RESET_ALERT_SETTINGS_ACTION: {
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false,
          completed: false
        }
      }
    }
    case types.SET_ALERT_SETTINGS_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      }
    }
    default:
      return state
  }
}

export default alertSettings
